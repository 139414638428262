@import "../../utils/breakpoints";

.aboutForum {
  position: relative;
  font-family: SBSansDisplay;

  @include breakpoints(md) {
    margin-top: 80px;
  }

  @include breakpoints(lg) {
    margin-top: 114px;
  }

  @include breakpoints(xl) {
    margin-top: 120px;
  }

  &__gradient {
    position: absolute;
    left: -340px;
    top: 290px;
    height: 720px;

    @include breakpoints(md) {
      left: -460px;
      top: 320px;
      height: 980px;
    }

    @include breakpoints(lg) {
      left: -640px;
      top: -120px;
      height: 1270px;
    }

    @include breakpoints(xl) {
      left: -840px;
      top: -120px;
      height: 1370px;
    }
  }

  &__bottom {
    @include breakpoints(md) {

    }

    @include breakpoints(lg) {
      margin-top: 48px;
    }

    @include breakpoints(xl) {
      margin-top: 64px;
    }
  }

  &__title {
    color: rgb(38, 38, 38);
    font-weight: 600;
    letter-spacing: 0px;
    font-size: 34px;
    line-height: 130%;
    margin: 24px 0;

    @include breakpoints(md) {
      font-size: 58px;
      line-height: 64px;
      margin: 0;
    }

    @include breakpoints(lg) {
      font-size: 58px;
      line-height: 64px;
    }

    @include breakpoints(xl) {
      font-size: 82px;
      line-height: 80px;
    }
  }

  &__subtitle {
    color: rgb(38, 38, 38);
    font-weight: 400;
    letter-spacing: -2%;
    text-align: left;
    line-height: 130%;
    font-size: 18px;

    @include breakpoints(md) {
      margin-top: 48px;
      font-size: 24px;
    }

    @include breakpoints(xl) {
      margin-top: 64px;
      font-size: 26px;
    }
  }

  &__image {

    &_top {
      background-position: top;
      background-image: url('../../assets/about_image_little_xs.png');
      height: 145px;
      background-size: cover;
      background-repeat: no-repeat;
      margin-top: 32px;
      border-radius: 10px;

      @include breakpoints(md) {
        border-radius: 20px;
        background-size: contain;
        height: 288px;
        margin-bottom: 48px;
      }

      @include breakpoints(lg) {
        margin: 0;
        border-radius: 20px;
        height: 100%;
        background-size: cover;
        background-image: url('../../assets/about_image_little.png');
      }
    }

    &_bottom {
      border-radius: 10px;
      background-size: cover;
      background-position: center;
      height: 236px;
      margin-top: 32px;
      background-image: url('../../assets/about_image_big.webp');

      @include breakpoints(md) {
        margin-top: 48px;
        border-radius: 20px;
        height: 505px;
      }

      @include breakpoints(lg) {
        margin-top: 0;
        height: 100%;
      }
    }
  }

  &__targets {
    &_title {
      font-size: 24px;
      line-height: 36px;
      color: rgb(38, 38, 38);
      font-weight: 600;
      letter-spacing: 0px;
      margin: 24px 0;

      @include breakpoints(md) {
        font-size: 44px;
        line-height: 52px;
        margin: 48px 0 32px;
      }

      @include breakpoints(lg) {
        font-size: 36px;
        line-height: 42px;
        margin-top: 0;
        margin-bottom: 32px;
      }

      @include breakpoints(xl) {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 25px;
      }
    }

    &_item {
      display: flex;

      @include breakpoints(lg) {
        flex-direction: column;
      }

      &_number {
        font-size: 24px;
        line-height: 42px;
        color: rgb(38, 38, 38);
        font-weight: 500;
        letter-spacing: 0px;
        margin-right: 18px;

        @include breakpoints(md) {
          font-size: 44px;
          line-height: 44px;
          margin-right: 32px;
        }

        @include breakpoints(lg) {
          font-size: 36px;
          line-height: 40px;
          margin: 12px 0;
        }

        @include breakpoints(xl) {
          font-size: 32px;
          line-height: 44px;
        }
      }

      &_subtitle {
        color: rgb(36, 39, 41);
        font-weight: 600;
        letter-spacing: -0.3px;
        font-size: 18px;
        line-height: 130%;

        @include breakpoints(lg) {
          font-size: 16px;
        }

        @include breakpoints(xl) {
          font-size: 18px;
        }
      }
    }

    &_separator {
      height: 2px;
      background-color: rgb(38, 38, 38);
      margin: 24px;

      @include breakpoints(md) {
        margin: 32px 0;
      }

      @include breakpoints(lg) {
        margin: 24px 0 0 0;
      }

      @include breakpoints(xl) {
        margin: 32px 0 0 0;
      }
    }
  }
}