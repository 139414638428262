@import "../../utils/breakpoints";

.footer {
  &__separator {
    height: 1px;
    background: #262626;
    margin-top: 48px;
    margin-bottom: 24px;

    @include breakpoints(md) {
      margin-top: 80px;
      margin-bottom: 32px;
    }

    @include breakpoints(lg) {
      margin-top: 100px;
      margin-bottom: 39px;
    }

    @include breakpoints(xl) {
      margin-top: 140px;
      margin-bottom: 48px;
    }
  }

  &__images {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoints(md) {
      display: none;
    }

    &_md {
      display: none;
      flex-direction: column;
      max-width: 202px;
      gap: 22px;

      @include breakpoints(md) {
        display: flex;
      }

      @include breakpoints(lg) {
        display: none;
      }
    }

    img {
      height: 32px;

      @include breakpoints(sm) {
        height: 38px;
      }
    }

    &_separator {
      background: #262626;
      width: 1px;
      height: 30px;
    }
  }

  &__legals {
    margin-top: 24px;
    margin-bottom: 32px;

    @include breakpoints(md) {
      margin-top: 0;
    }

    &_legal {

    }

    &_item {
      margin-top: 12px;
      text-decoration: none;
      color: rgb(38, 38, 38);
      font-family: SBSansText;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -2%;
      text-align: left;

      @include breakpoints(lg) {
        font-size: 18px;
        line-height: 24px;
      }

      &_md {
        display: none;

        @include breakpoints(md) {
          display: flex;
        }

        @include breakpoints(lg) {
          display: none;
        }

        &-hidden {
          @include breakpoints(md) {
            display: none;
          }

          @include breakpoints(lg) {
            display: block;
          }
        }
      }

      a {
        color: rgb(38, 38, 38);

        &:hover {
          color: #515151;
        }
      }
    }

    &_right {
      @include breakpoints(md) {
        &:before {
          position: absolute;
          left: -8px;
          width: 1px;
          height: 100%;
          background: black;
          content: "";
        }
      }

      @include breakpoints(lg) {
        &:before {
          //left: -46px;
        }
      }
    }
  }
}