@import "../../utils/breakpoints";

.schedule {
  margin-top: 48px;

  @include breakpoints(md) {
    margin-top: 80px;
  }

  @include breakpoints(lg) {
    margin-top: 110px;
  }

  @include breakpoints(xl) {
    margin-top: 116px;
  }

  &__title {
    font-family: SBSansDisplay;
    color: rgb(38, 38, 38);
    font-size: 34px;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: 0px;
    text-align: left;

    @include breakpoints(md) {
      font-size: 58px;
      line-height: 64px;
    }

    @include breakpoints(xl) {
      font-size: 82px;
      line-height: 80px;
    }
  }

  &__button {
    border: 1px solid rgb(0, 0, 0);
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 16px 11px 16px;
    text-decoration: none;
    color: rgb(39, 39, 41);
    font-family: SBSansText;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.2px;
    margin-top: 24px;
    gap: 8px;

    @include breakpoints(md) {
      margin-top: 0;
    }

    &_wrapper {
      @include breakpoints(md) {
        display: flex;
        align-items: center;
      }

      @include breakpoints(lg) {
        justify-content: flex-end;
      }
    }
  }

  &__events {
    margin-top: 24px;

    @include breakpoints(md) {
      margin-top: 48px;
    }

    @include breakpoints(lg) {
      margin-top: 51px;
    }

    @include breakpoints(xl) {
      margin-top: 66px;
    }
  }

  &__event {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: rgb(38, 38, 38);
    font-family: SBSansText;
    font-size: 18px;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -2%;

    @include breakpoints(md) {
      font-size: 24px;
      line-height: 130%;
    }

    @include breakpoints(lg) {
      gap: 86px;
      flex-direction: row;
    }

    @include breakpoints(xl) {
      font-size: 26px;
      line-height: 130%;
    }

    &_time {
      text-wrap: nowrap;
      color: rgb(38, 38, 38);
      font-family: SBSansText;
      font-size: 18px;
      font-weight: 600;
      line-height: 130%;
      letter-spacing: -2%;

      @include breakpoints(md) {
        font-size: 24px;
        line-height: 130%;
      }

      @include breakpoints(xl) {
        font-size: 26px;
        line-height: 130%;
      }
    }

    &_header {
      color: rgb(38, 38, 38);
      font-family: SBSansText;
      font-size: 18px;
      font-weight: 600;
      line-height: 130%;
      letter-spacing: -2%;

      @include breakpoints(md) {
        font-size: 24px;
        line-height: 130%;
      }

      @include breakpoints(xl) {
        font-size: 26px;
        line-height: 130%;
      }
    }

    &_description {
      color: rgb(38, 38, 38);
      font-family: SBSansText;
      font-size: 16px;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: -0.3px;
      margin-top: 12px;

      @include breakpoints(md) {
        font-size: 18px;
        margin-top: 18px;
      }

      &_hidden {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3; // количество строк
        -webkit-box-orient: vertical;
      }
    }

    &_button {
      font-size: 16px;
      font-weight: 600;
      line-height: 130%;
      letter-spacing: -0.3px;
      cursor: pointer;
      margin-top: 8px;
      user-select: none;

      @include breakpoints(md) {
        margin-top: 12px;
      }
    }

    &_row {
      width: 100%;

      &_speakers {
        display: flex;
        flex-wrap: wrap;
        margin-top: 4px;

        @include breakpoints(md) {
          margin-top: 6px;
        }
      }
    }

    &_speaker {
      display: flex;
      gap: 14px;
      width: 100%;
      margin-top: 18px;

      @include breakpoints(md) {
        width: 50%;
        margin-top: 20px;
      }

      img {
        border-radius: 50%;
        min-width: 67px;
        width: 67px;
        height: 67px;
      }
    }

    &_name {
      font-family: SBSansText;
      font-size: 18px;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: -0.3px;
    }

    &_place {
      font-family: SBSansText;
      color: rgb(129, 135, 142);
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: -0.2px;
      display: flex;
      gap: 4px;
      margin-top: 4px;

      &_sep {
        min-width: 3px;
        width: 3px;
        height: 16px;
        border-radius: 10px;
        background-color: rgb(214, 220, 226);
      }
    }

    &_tag {
      font-family: SBSansText;
      border: 1px solid rgba(207, 212, 221, 0.7);
      border-radius: 14px;
      padding: 7px 8px 7px 8px;
      font-size: 13px;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: -0.2px;
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 7px;
      margin-top: 10px;
    }

    a {
      text-decoration: none;
      color: rgb(38, 38, 38);
    }

    &_separator {
      height: 2px;
      background-color: rgb(38, 38, 38);
      margin: 32px 0;

      @include breakpoints(lg) {
        margin: 44px 0;
      }
    }
  }

  &__popup {

    &_modal {
      display: flex;
      flex-direction: column;
      position: relative;
      border-radius: 18px;
      box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.1);
      background: rgb(255, 255, 255);
      height: fit-content;
      max-height: 100vh;
      overflow: auto;
      padding: 47px 24px;
      gap: 24px;

      @include breakpoints(md) {
        padding: 64px 48px;
        max-width: 705px;
      }

      @include breakpoints(lg) {
        gap: 32px;
        padding: 64px;
        max-width: 802px;
      }

      @include breakpoints(xl) {
        gap: 32px;
        padding: 80px;
        max-width: 1028px;
      }

      &_close {
        position: absolute;
        cursor: pointer;
        top: 25px;
        right: 25px;
      }

      .schedule__event_time {
        font-size: 18px;

        @include breakpoints(md) {
          font-size: 24px;
        }

        @include breakpoints(xl) {
          font-size: 26px;
        }
      }

      .schedule__event_header {
        font-size: 18px;

        @include breakpoints(md) {
          font-size: 24px;
        }

        @include breakpoints(xl) {
          font-size: 26px;
        }
      }

      .schedule__event_description {

      }
    }
  }
}

.popup-overlay {
  background: rgba(0, 0, 0, .5);
}