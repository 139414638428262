@import "../../utils/breakpoints";

.businessFacts {
  font-family: SBSansDisplay;
  margin-top: 48px;

  @include breakpoints(md) {
    margin-top: 80px;
  }

  @include breakpoints(lg) {
    margin-top: 82px;
  }

  @include breakpoints(xl) {

  }

  &__header {
    color: rgb(38, 38, 38);
    font-size: 34px;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: 0px;

    @include breakpoints(md) {
      font-size: 58px;
      line-height: 64px;
    }

    @include breakpoints(xl) {
      font-size: 82px;
      line-height: 82px;
    }
  }

  &__card {
    color: rgb(38, 38, 38);
    margin-top: 24px;

    @include breakpoints(md) {
      margin-top: 32px;
    }

    @include breakpoints(lg) {
      margin-top: 48px;
    }

    &_fact {
      font-size: 24px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0px;
      text-wrap: nowrap;

      @include breakpoints(md) {
        font-size: 48px;
        line-height: 54px;
      }

      @include breakpoints(lg) {
        font-size: 48px;
        line-height: 54px;
      }

      @include breakpoints(xl) {
        font-size: 58px;
        line-height: 68px;
      }
    }

    &_separator {
      height: 2px;
      background-color: rgb(38, 38, 38);
      margin: 12px 0;

      @include breakpoints(md) {
        margin: 20px 0;
      }
    }

    &_subfact {
      font-size: 14px;
      line-height: 130%;
      letter-spacing: -2%;

      @include breakpoints(md) {
        font-size: 16px;
      }

      @include breakpoints(xl) {
        font-size: 18px;
      }
    }
  }
}