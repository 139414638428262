@import "../../utils/breakpoints";

.cookies {
  z-index: 666;
  position: fixed;
  bottom: 10px;
  margin: 0 auto;

  &__banner {
    background: #FFFFFF;
    box-sizing: border-box;
    border: 1px solid rgb(38, 38, 38);
    border-radius: 12px;
    padding: 27px 12px;

    @include breakpoints(md) {
      padding: 18px;
    }

    &_text {
      color: rgba(38, 38, 38, 0.7);
      font-family: SBSansDisplay;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -2%;
      text-align: left;

      a {
        color: rgba(38, 38, 38, 0.7);
      }
    }

    &_button {
      border: 1px solid rgb(38, 38, 38);
      border-radius: 50px;
      color: rgb(38, 38, 38);
      font-family: SBSansDisplay;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      letter-spacing: 0;
      text-align: center;
      padding: 20px;
      margin-top: 46px;
      text-transform: uppercase;
      cursor: pointer;

      @include breakpoints(md) {
        margin-top: 0;
      }
    }
  }
}