@import "../../utils/breakpoints";

.speakers {
  margin-top: 48px;
  position: relative;

  @include breakpoints(md) {
    margin-top: 80px;
  }

  @include breakpoints(lg) {
    margin-top: 110px;
  }

  @include breakpoints(xl) {
    margin-top: 120px;
  }

  &__gradient {
    position: absolute;
    right: -340px;
    top: -50px;
    height: 720px;

    @include breakpoints(md) {
      right: -460px;
      top: 320px;
      height: 980px;
    }

    @include breakpoints(lg) {
      right: -640px;
      top: -120px;
      height: 1270px;
    }

    @include breakpoints(xl) {
      right: -840px;
      top: -120px;
      height: 1370px;
    }
  }

  &__title {
    font-family: SBSansDisplay;
    color: rgb(38, 38, 38);
    font-size: 34px;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: 0px;

    @include breakpoints(md) {
      font-size: 58px;
      line-height: 64px;
    }

    @include breakpoints(xl) {
      font-size: 82px;
      line-height: 80px;
    }
  }

  &__slider {
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 14px;
    list-style: none;
    z-index: 1;
    display: block;
    overflow: hidden;

    @include breakpoints(md) {
      margin-top: 48px;
    }

    @include breakpoints(lg) {
      margin-top: 51px;
    }

    @include breakpoints(xl) {
      margin-top: 66px;
    }

    .swiper-wrapper {
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      transition-property: transform;
      transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
      box-sizing: content-box;
    }

    .swiper-android .swiper-slide,
    .swiper-ios .swiper-slide,
    .swiper-wrapper {
      transform: translate3d(0px, 0, 0);
    }

    .swiper-horizontal {
      touch-action: pan-y;
    }

    .swiper-slide {
      flex-shrink: 0;
      position: relative;
      display: flex;
      flex-direction: column;
    }

    .swiper-pagination-bullets {
      display: flex;
      column-gap: 16px;
      justify-content: center;
      align-items: center;
      width: 100%;
      z-index: 1;
      padding-top: 24px;

      @include breakpoints(lg) {
        display: none;
      }
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      background-color: rgba(53, 63, 71, 0.3);
      margin: 0;
      border-radius: 50%;
      transition: width 0.15s ease-in-out;
    }

    .swiper-pagination-bullet-active {
      width: 32px;
      height: 8px;
      border-radius: 200px;
      background: #48d553;
    }

    .swiper-button-prev {
      cursor: pointer;
      position: absolute;
      z-index: 11;
      display: none;

      &:after {
        border-radius: 50%;
        content: url("../../assets/left.svg");
      }

      @include breakpoints(lg) {
        display: block;
        top: 42%;
        left: -30px;
      }
    }

    .swiper-button-next {
      cursor: pointer;
      z-index: 11;
      position: absolute;
      display: none;

      &:after {
        border-radius: 50%;
        content: url("../../assets/right.svg");
      }

      @include breakpoints(lg) {
        display: block;
        right: -30px;
        top: 42%;
      }
    }

    .swiper-button-disabled {
      display: none !important;
      opacity: 0.5;
      cursor: auto;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoints(md) {
      align-items: start;
    }

    &_photo {
      width: 164px;
      height: 164px;
      border-radius: 50%;
      overflow: hidden;
      background: #d1d1d1;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
      }

      @include breakpoints(md) {
        width: 268px;
        height: 268px;
      }
    }

    &_name {
      font-family: SBSansDisplay;
      color: rgb(36, 39, 41);
      font-size: 18px;
      font-weight: 600;
      line-height: 130%;
      letter-spacing: -0.3px;
      margin-top: 16px;
      text-align: center;

      @include breakpoints(md) {
        text-align: left;
      }
    }

    &_description {
      color: rgb(36, 39, 41);
      font-family: SBSansText;
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: -0.2px;
      text-align: center;
      margin-top: 4px;

      @include breakpoints(md) {
        text-align: left;
      }
    }

    &_titul {
      color: rgb(39, 39, 41);
      font-family: SBSansText;
      font-size: 18px;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: -0.2px;
      text-align: center;
      margin-top: 16px;

      @include breakpoints(md) {
        text-align: left;
      }
    }
  }
}