$breakpoints: (
        "xs": 0px,
        "sm": 375px,
        "md": 768px,
        "lg": 1024px,
        "xl": 1440px,
        "extra": 1366px,
) !default;

@mixin breakpoints($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
            + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}