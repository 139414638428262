@import "../../utils/breakpoints";

.banner {
  &__title {
    padding-top: 32px;
    color: rgb(38, 38, 38);
    font-family: SBSansDisplay;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
    font-size: 37px;
    line-height: 37px;
    width:100%;

      
    @include breakpoints(xs) {
      font-size: 30px;
      line-height: 34px;
    }

    @include breakpoints(sm) {
      font-size: 30px;
      line-height: 34px;
    }

    @include breakpoints(md) {
      padding-top: 48px;
      //padding-bottom: 48px;
      line-height: 60px;
      font-size: 60px;
 
      //border-bottom: 1px solid rgb(38, 38, 38);
    }

    @include breakpoints(lg) {
      padding-top: 65px;
      border: none;
      padding-bottom: 32px;
      font-size: 48px;
      line-height: 54px;
      width: 93%;
    }

    @include breakpoints(xl) {
      font-size: 60px;
      line-height: 60px;
      width: 90%;
    }

  }

  &__subtitle {
    padding-top: 18px;
    color: rgb(38, 38, 38);
    font-family: SBSansDisplay;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -2%;
    text-align: left;
    width: 100%;


    @include breakpoints(md) {
      padding-top: 24px;
      font-size: 24px;
      line-height: 30px;
      width:70%;
    }

    @include breakpoints(lg) {
      padding-top: 0;
      font-size: 24px;
      line-height: 130%;
      width:80%;
    }

    @include breakpoints(lg) {
      font-size: 26px;
    }

    &_separator {
      display: none;

      @include breakpoints(md) {
        display: block;
        width: 1px;
        background: #262626;
        height: 86px;
        margin-top: 32px;
      }

      @include breakpoints(lg) {
        display: none;
      }
    }
  }

  &__event {
    margin-top: 32px;
    border-top: 1px solid #262626;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: flex-start;
    flex-direction: column;

    @include breakpoints(md) {
      border: none;
      align-items: center;
      gap: 44px;
      padding: 0;
      flex-direction: row;
      border-top: 1px solid #262626;
      border-bottom: 1px solid #262626;
      justify-content: flex-start;
    }

    @include breakpoints(lg) {
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 81px;
      flex-direction: column;
      border: none;
      gap: 42px;

      &:before {
        position: absolute;
        left: -30px;
        content: "";
        height: 100%;
        width: 1px;
        background: black;
        //content: url("../../assets/line.svg");
      }
    }

    @include breakpoints(xl) {
      &:before {
        left: -40px;
      }
    }

    &_row {
      display: flex;
      align-items: flex-end;
      width: 100%;
      padding: 24px 0;
      border-bottom: 1px solid #262626;
      gap: 20px;


      @include breakpoints(md) {
        justify-content: space-between;
        border: none;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px 0;
        gap: 16px;
        width: auto;
      }

      @include breakpoints(lg) {
        width: 100%;
        padding: 0 0 42px 0;
      }

      &-separator {
        width: 1px;
        height: 70px;
        background: black;
        display: none;

        @include breakpoints(md) {
          display: block;
        }

        @include breakpoints(lg) {
          width: 100%;
          height: 1px;
        }
      }
    }

    &_title {
      color: rgb(38, 38, 38);
      font-family: SBSansText;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -2%;
      text-align: left;
      text-transform: uppercase;
      width: 60px;

      @include breakpoints(md) {
        width: auto;
        font-size: 18px;
        line-height: 22px;
      }

      @include breakpoints(lg) {
        //margin-top: 16px;
      }
    }

    &_date {
      text-decoration: none;
      color: rgb(38, 38, 38);
      font-family: SBSansDisplay;
      font-size: 24px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0;
      text-align: left;

      @include breakpoints(md) {
        font-size: 36px;
        line-height: 46px;
      }

      @include breakpoints(lg) {
        font-size: 28px;
        line-height: 36px;
      }

      @include breakpoints(xl) {
        font-size: 36px;
        line-height: 46px;
      }
    }

    &_button {
      display: none;
      width: 100%;

      @include breakpoints(lg) {
        display: block;
        //padding-top: 32px;
        //border-top: 1px solid #262626;
      }
    }
  }

  &__button {
    margin-top: 32px;
    padding: 20px;
    background: #262626;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-family: SBSansDisplay;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;

    &:hover {
      background: rgb(57, 57, 57);
    }

    @include breakpoints(md) {
      padding: 25px;
      font-size: 18px;
      line-height: 21px;
      margin-top: 48px;
    }

    @include breakpoints(lg) {
      margin: 0;
    }

    &_separator {
      display: none;

      @include breakpoints(md) {
        display: block;
        background: #262626;
        height: 1px;
        margin-top: 28px;
      }
    }

    &_col {
      @include breakpoints(lg) {
        display: none;
      }
    }
  }
}