@import "../../utils/breakpoints";

.header {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #262626;

    @include breakpoints(md) {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    @include breakpoints(lg) {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    &__logosWrapper {
        display: flex;

        @include breakpoints(lg) {
            width: 100%;
            justify-content: space-between;
            padding-right: 68px;
        }

        &_desktop {
            display: none;

            @include breakpoints(lg) {
                display: flex;
                align-items: center;
            }
        }
    }

    &__logo {
        &_separator {
            display: none;

            @include breakpoints(md) {
                display: block;
                width: 1px;
                height: 39px;
                background: #262626;
                margin: 0 24px;
            }

            @include breakpoints(lg) {
                &:first-child {
                    display: none;
                }
            }
        }

        &_main {
            width: auto;
            height: 54px;

            @include breakpoints(lg) {
                height: 76px;
            }

            @include breakpoints(lg) {
                height: 86px;
            }
        }

        &_maib {
            width: 207px;

            @include breakpoints(lg) {
                width: 273px;
                height: 58px;
            }
        }

        &_sber {
            width: 83px;
            height: 22px;

            @include breakpoints(lg) {
                width: 105px;
                height: 28px;
            }

            &:hover {
                filter: contrast(0.5);
            }
        }
    }

    &__langSwitch {
        text-decoration: none;
        display: flex;
        align-items: center;
        color: rgb(38, 38, 38);
        font-family: SBSansText;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: -2%;
        text-align: left;
        text-transform: uppercase;

        &:hover {
            color: #515151;
        }
    }
}
