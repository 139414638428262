@font-face {
  font-family: SBSanstext;
  src: url("./fonts/sbsanstext-bold.eot");
  src: url("./fonts/sbsanstext-bold.eot?#iefix") format("embedded-opentype"),
  url("./fonts/sbsanstext-bold.woff2") format("woff2"), url("./fonts/sbsanstext-bold.woff") format("woff"),
  url("./fonts/sbsanstext-bold.ttf") format("truetype"), url("./fonts/sbsanstext-bold.svg") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SBSanstext;
  src: url("./fonts/sbsanstext-semibold.eot");
  src: url("./fonts/sbsanstext-semibold.eot?#iefix") format("embedded-opentype"),
  url("./fonts/sbsanstext-semibold.woff2") format("woff2"), url("./fonts/sbsanstext-semibold.woff") format("woff"),
  url("./fonts/sbsanstext-semibold.ttf") format("truetype"), url("./fonts/sbsanstext-semibold.svg") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SBSanstext;
  src: url("./fonts/sbsanstext-regular.eot");
  src: url("./fonts/sbsanstext-regular.eot?#iefix") format("embedded-opentype"),
  url("./fonts/sbsanstext-regular.woff2") format("woff2"), url("./fonts/sbsanstext-regular.woff") format("woff"),
  url("./fonts/sbsanstext-regular.ttf") format("truetype"), url("./fonts/sbsanstext-regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SBSanstext;
  src: url("./fonts/sbsanstext-light.eot");
  src: url("./fonts/sbsanstext-light.eot?#iefix") format("embedded-opentype"),
  url("./fonts/sbsanstext-light.woff2") format("woff2"), url("./fonts/sbsanstext-light.woff") format("woff"),
  url("./fonts/sbsanstext-light.ttf") format("truetype"), url("./fonts/sbsanstext-light.svg") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SBSansDisplay;
  src: url("./fonts/sbsansdisplay-semibold.eot");
  src: url("./fonts/sbsansdisplay-semibold.eot?#iefix") format("embedded-opentype"),
  url("./fonts/sbsansdisplay-semibold.woff2") format("woff2"),
  url("./fonts/sbsansdisplay-semibold.woff") format("woff"),
  url("./fonts/sbsansdisplay-semibold.ttf") format("truetype"),
  url("./fonts/sbsansdisplay-semibold.svg") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SBSansDisplay;
  src: url("./fonts/sbsansdisplay-regular.eot");
  src: url("./fonts/sbsansdisplay-regular.eot?#iefix") format("embedded-opentype"),
  url("./fonts/sbsansdisplay-regular.woff2") format("woff2"),
  url("./fonts/sbsansdisplay-regular.woff") format("woff"),
  url("./fonts/sbsansdisplay-regular.ttf") format("truetype"),
  url("./fonts/sbsansdisplay-regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

 @font-face {
     font-family: SBSansDisplay;
     src: url("./fonts/sbsansdisplay-regular.eot");
     src: url("./fonts/sbsansdisplay-regular.eot?#iefix") format("embedded-opentype"),
         url("./fonts/sbsansdisplay-regular.woff2") format("woff2"), url("./fonts/sbsansdisplay-regular.woff") format("woff"),
         url("./fonts/sbsansdisplay-regular.ttf") format("truetype"), url("./fonts/sbsansdisplay-regular.svg") format("svg");
     font-weight: 400;
     font-style: normal;
     font-display: swap;
 }

@font-face {
  font-family: SBSansDisplay;
  src: url("./fonts/sbsansdisplay-thin.eot");
  src: url("./fonts/sbsansdisplay-thin.eot?#iefix") format("embedded-opentype"),
  url("./fonts/sbsansdisplay-thin.woff2") format("woff2"), url("./fonts/sbsansdisplay-thin.woff") format("woff"),
  url("./fonts/sbsansdisplay-thin.ttf") format("truetype"), url("./fonts/sbsansdisplay-thin.svg") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SBSansDisplay;
  src: url("./fonts/sbsansdisplay-light.eot");
  src: url("./fonts/sbsansdisplay-light.eot?#iefix") format("embedded-opentype"),
  url("./fonts/sbsansdisplay-light.woff2") format("woff2"), url("./fonts/sbsansdisplay-light.woff") format("woff"),
  url("./fonts/sbsansdisplay-light.ttf") format("truetype"), url("./fonts/sbsansdisplay-light.svg") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

 @font-face {
     font-family: SBSansDisplay;
     src: url("./fonts/sbsansdisplay-bold.eot");
     src: url("./fonts/sbsansdisplay-bold.eot?#iefix") format("embedded-opentype"),
         url("./fonts/sbsansdisplay-bold.woff2") format("woff2"), url("./fonts/sbsansdisplay-bold.woff") format("woff"),
         url("./fonts/sbsansdisplay-bold.ttf") format("truetype"), url("./fonts/sbsansdisplay-bold.svg") format("svg");
     font-weight: 700;
     font-style: normal;
     font-display: swap;
 }

 @font-face {
     font-family: SBSansDisplay;
     src: url("./fonts/sbsansdisplay-semibold.eot");
     src: url("./fonts/sbsansdisplay-semibold.eot?#iefix") format("embedded-opentype"),
         url("./fonts/sbsansdisplay-semibold.woff2") format("woff2"), url("./fonts/sbsansdisplay-semibold.woff") format("woff"),
         url("./fonts/sbsansdisplay-semibold.ttf") format("truetype"), url("./fonts/sbsansdisplay-semibold.svg") format("svg");
     font-weight: 600;
     font-style: normal;
     font-display: swap;
 }