@import "../../../utils/breakpoints";

.bottom-gradient {
  position: absolute;
  z-index: 0;

  &_left {
    left: -340px;
    height: 720px;

    @include breakpoints(md) {
      left: -460px;
      height: 980px;
    }

    @include breakpoints(lg) {
      left: -640px;
      height: 1270px;
    }

    @include breakpoints(xl) {
      left: -550px;
      height: 1370px;
    }
  }

  &_right {
    right: -340px;
    height: 720px;

    @include breakpoints(md) {
      right: -460px;
      height: 980px;
    }

    @include breakpoints(lg) {
      right: -640px;
      height: 1270px;
    }

    @include breakpoints(xl) {
      right: -550px;
      height: 1370px;
    }
  }
}