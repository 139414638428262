@import "../../../utils/breakpoints";

.top-gradient {
  position: absolute;
  top: -200px;
  right: -200px;
  z-index: 0;
  transform: rotate(157deg);
  filter: blur(70px);
  background: linear-gradient(300.16deg, rgb(157, 174, 248) 17.141%,rgb(120, 203, 234) 40.652%,rgb(116, 232, 191) 66.027%,rgba(128, 237, 185, 0.24) 79.916%);
  width: 605px;
  height: 605px;
  border-radius: 50%;
  opacity: .9;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);

  @include breakpoints(md) {
    top: -480px;
    right: -520px;
    width: 977.32px;
    height: 984.07px;
  }

  @include breakpoints(lg) {
    top: -455px;
    right: -720px;
    width: 1250px;
    height: 1000px;
  }

  @include breakpoints(xl) {
    top: -470px;
    right: -360px;
    height: 1150px;
    width: 1250px;
  }

  &_img {
    position: absolute;
    top: -280px;
    right: -290px;

    @include breakpoints(md) {
      top: -600px;
      right: -670px;
    }

    @include breakpoints(lg) {
      top: -610px;
      right: -500px;
    }

    @include breakpoints(xl) {
      top: -730px;
      right: -430px;
    }
  }
}