@import "../../utils/breakpoints";

.aboutRief {
  z-index: 666;
  margin-top: 48px;

  @include breakpoints(md) {
    margin-top: 80px;
  }

  @include breakpoints(lg) {
    margin-top: 110px;
  }

  @include breakpoints(xl) {
    margin-top: 116px;
  }

  &__header {
    color: rgb(38, 38, 38);
    font-family: SBSansDisplay;
    font-size: 34px;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: 0px;
    text-align: left;

    @include breakpoints(md) {
      font-size: 58px;
      line-height: 64px;
      text-align: center;
    }

    @include breakpoints(xl) {
      font-size: 82px;
      line-height: 80px;
    }
  }

  &__subtitle {
    margin-top: 16px;
    color: rgb(38, 38, 38);
    font-family: SBSansDisplay;
    font-size: 18px;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -2%;
    text-align: left;

    @include breakpoints(md) {
      font-size: 24px;
      text-align: center;
      margin-top: 24px;
    }

    @include breakpoints(lg) {
      margin-top: 32px;
    }

    @include breakpoints(xl) {
      font-size: 26px;
      line-height: 130%;
      margin-top: 48px;
      max-width: 791px;
    }

    &_col {
      display: flex;
      justify-content: center;
    }
  }

  &__road {
    display: flex;
    align-items: center;
    width: 296px;
    margin-top: 45px;

    @include breakpoints(md) {
      flex-direction: column;
    }

    @include breakpoints(xl) {
      margin-top: 73px;
    }

    &_wrapper {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;

      @include breakpoints(md) {
        flex-direction: row;
      }
    }

    &_city {
      display: block;
      width: 40%;
      text-align: end;
      color: rgb(36, 39, 41);
      font-family: SBSansDisplay;
      font-size: 24px;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: -0.3px;

      @include breakpoints(md) {
        width: auto;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 48px;
      }

      @include breakpoints(xl) {
        font-size: 32px;
        line-height: 42px;
      }
    }

    &_dot-wrapper {
      width: 10%;
      display: flex;
      justify-content: center;

      @include breakpoints(md) {
        width: auto;
      }
    }

    &_dot {
      position: relative;
      width: 12px;
      height: 12px;
      border: 1px solid black;
      border-radius: 50%;

      &:before {
        content: "";
        height: 350%;
        top: -350%;
        left: calc(50% - 0.5px);
        position: absolute;
        width: 1px;
        background: black;

        @include breakpoints(md) {
          height: 1px;
          top: calc(50% - 0.5px);
          left: -570%;
          width: 560%;
        }

        @include breakpoints(lg) {
          left: -785%;
          width: 785%;
        }

        @include breakpoints(xl) {
          left: -930%;
          width: 930%;
        }
      }

      &:after {
        content: "";
        height: 350%;
        bottom: -350%;
        left: calc(50% - 0.5px);
        position: absolute;
        width: 1px;
        background: black;

        @include breakpoints(md) {
          height: 1px;
          top: calc(50% - 0.5px);
          left: auto;
          right: -570%;
          width: 560%;
        }

        @include breakpoints(lg) {
          right: -785%;
          width: 785%;
        }

        @include breakpoints(xl) {
          right: -930%;
          width: 930%;
        }
      }
    }

    &_date {
      width: 40%;
      display: flex;
      gap: 8px;
      align-items: end;

      @include breakpoints(md) {
        flex-direction: column-reverse;
        align-items: center;
        width: auto;
        margin-top: 45px;
      }

      &-year {
        color: rgb(36, 39, 41);
        font-family: SBSansDisplay;
        font-size: 24px;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: -0.3px;

        @include breakpoints(md) {
          font-size: 24px;
          font-weight: 600;
          line-height: 42px;
        }

        @include breakpoints(xl) {
          font-size: 32px;
        }
      }

      &-month {
        color: rgb(38, 38, 38);
        font-family: SBSansDisplay;
        font-size: 14px;
        font-weight: 400;
        line-height: 170%;
        letter-spacing: -2%;

        @include breakpoints(md) {
          font-size: 24px;
          line-height: 130%;
        }

        @include breakpoints(xl) {
          font-size: 26px;
        }
      }
    }
  }

  &__wallpapers {
    display: none;

    @include breakpoints(md) {
      display: block;
      margin-top: 80px;
    }

    @include breakpoints(lg) {
      margin-top: 110px;
    }

    @include breakpoints(xl) {
      margin-top: 120px;
    }


    &_row{
      display: grid;
      grid-template-columns: 1fr 2.265129683fr;
      grid-template-rows: 1fr;
      margin-top: 24px;
      grid-column-gap: 24px;

      @include breakpoints(lg) {
        margin-top: 32px;
        grid-column-gap: 34px;
      }

      &:first-child {
        grid-area: 1 / 1 / 2 / 2;
      }

      &:last-child {
        grid-area: 1 / 2 / 2 / 3;
      }
    }

    &_row-reverse {
      display: grid;
      grid-template-columns: 2.265129683fr 1fr;
      grid-template-rows: 1fr;
      margin-top: 24px;
      grid-column-gap: 24px;

      @include breakpoints(lg) {
        margin-top: 32px;
        grid-column-gap: 34px;
      }

      &:first-child {
        grid-area: 1 / 1 / 2 / 2;
      }

      &:last-child {
        grid-area: 1 / 2 / 2 / 3;
      }
    }

    &_image {
      width: 100%;
      border-radius: 10px;
      background-repeat: no-repeat;
      background-size: cover;
      height: 236px;

      @include breakpoints(md) {
        background-position: center;
        border-radius: 20px;
        height: 210px;
      }

      @include breakpoints(lg) {
        height: 288px;
      }

      @include breakpoints(xl) {
        height: 348px;
      }

      &-1 {
        background-image: url("../../assets/Mask-1.jpeg");
        @include breakpoints(md) {
          background-image: url("../../assets/bottom_1.png");
        }
      }

      &-2 {
        background-image: url("../../assets/Mask-2.jpeg");

        @include breakpoints(md) {
          background-image: url("../../assets/bottom_2.png");
        }
      }

      &-3 {
        background-image: url("../../assets/Mask-3.jpeg");

        @include breakpoints(md) {
          background-image: url("../../assets/bottom_3.png");
        }
      }

      &-4 {
        background-image: url("../../assets/Mask-4.jpeg");
      }

      &-5 {
        background-image: url("../../assets/Mask-5.jpeg");

        @include breakpoints(md) {
          background-image: url("../../assets/bottom_5.png");
        }
      }

      &-6 {
        background-image: url("../../assets/bottom_6.png");
      }
    }
  }

  &__slider {
    z-index: 666;
    margin-top: 67px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 14px;
    list-style: none;
    overflow: hidden;
    display: block;

    @include breakpoints(md) {
      display: none;
      margin-top: 48px;
    }

    .swiper-wrapper {
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      transition-property: transform;
      transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
      box-sizing: content-box;
    }

    .swiper-android .swiper-slide,
    .swiper-ios .swiper-slide,
    .swiper-wrapper {
      transform: translate3d(0px, 0, 0);
    }

    .swiper-horizontal {
      touch-action: pan-y;
    }

    .swiper-slide {
      flex-shrink: 0;
      position: relative;
      display: flex;
      flex-direction: column;
    }

    .swiper-pagination-bullets {
      display: flex;
      column-gap: 16px;
      justify-content: center;
      align-items: center;
      width: 100%;
      z-index: 1;
      padding-top: 24px;

      @include breakpoints(lg) {
        display: none;
      }
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      background-color: rgba(53, 63, 71, 0.3);
      margin: 0;
      border-radius: 50%;
      transition: width 0.15s ease-in-out;
      z-index: 666;
    }

    .swiper-pagination-bullet-active {
      width: 32px;
      height: 8px;
      border-radius: 200px;
      background: #48d553;
    }

    .swiper-button-prev {
      cursor: pointer;
      position: absolute;
      z-index: 11;
      display: none;

      &:after {
        border-radius: 50%;
        content: url("../../assets/left.svg");
      }

      @include breakpoints(lg) {
        display: block;
        top: 42%;
        left: -30px;
      }
    }

    .swiper-button-next {
      cursor: pointer;
      z-index: 11;
      position: absolute;
      display: none;

      &:after {
        border-radius: 50%;
        content: url("../../assets/right.svg");
      }

      @include breakpoints(lg) {
        display: block;
        right: -30px;
        top: 42%;
      }
    }

    .swiper-button-disabled {
      display: none !important;
      opacity: 0.5;
      cursor: auto;
    }
  }
}